import React, {useState} from "react";
import {Button, Grid} from "@mui/material";
import {StyledBoxColumn, StyledBoxRow, StyledContainer, StyledSpan} from "shared/StyledComponents";
import {DIVIDER_BLACK, PD_XLG} from "../../shared/dimens";
import {FileUploadOutlined} from "@mui/icons-material";
import {brightPaletteColors} from "../../shared/colors";
import {FormGenContainer} from "../../shared/FormGenContainer";
import {BaseApp} from "../../shared/BaseApp";
import {StoredDataFileUploadFragment} from "../../shared/StoredDateFileUploadFragment";
import {Klip, Klips} from "../types";
import {md5_code} from "../../shared/md5";
import {MainMode} from "../Main";

function onUploadCover(setCoverImageUrl: (url: string) => void) {
  BaseApp.CONTEXT.showDialog(null, () => <StoredDataFileUploadFragment
    mimeTypes={["image/*"]}
    storedDataSource={"profile-photos"}
    onSelectionChanged={(urls: string[]) => {
      setCoverImageUrl(urls[0]);
    }}/>);
}

export function Save(props: { klip: Klip, draftText: string, setMode: (mode: MainMode) => void }) {
  const draftText = props.draftText;
  // console.log("draftText: " + props.draftText);
  const klipMetadata = props.klip.metadata.clone();
  const [coverImageUrl, setCoverImageUrl] = useState<string>(props.klip.coverImageUrl);
  return <StyledContainer size="xlg" style={{padding: PD_XLG}}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <StyledBoxColumn style={{alignItems: "center"}}>
          <img
            src={coverImageUrl}
            style={{
              display: "block",
              width: "100%",
              aspectRatio: 1,
              border: DIVIDER_BLACK,
              background: brightPaletteColors[md5_code(props.klip.id) % brightPaletteColors.length],
            }}/>
          {/*<Button startIcon={<CameraAltOutlined/>}>Update screenshot</Button>*/}
          <Button
            startIcon={<FileUploadOutlined/>}
            onClick={() => onUploadCover(setCoverImageUrl)}>
            Upload cover
          </Button>
        </StyledBoxColumn>
      </Grid>
      <Grid item xs={12} sm={8}>
        <StyledBoxColumn>
          <FormGenContainer content={klipMetadata}/>
          {/*<Typography variant="h5">Title</Typography>*/}
          {/*<StyledBoxRow>*/}
          {/*  <TextField*/}
          {/*    size="small"*/}
          {/*    placeholder="Type a short name for this klip..."*/}
          {/*    style={{flexGrow: 1}}/>*/}
          {/*</StyledBoxRow>*/}
          {/*<Typography variant="h5">Description</Typography>*/}
          {/*<TextareaAutosize style={{fontSize: "125%", padding: PD_MD}} minRows={6}/>*/}
        </StyledBoxColumn>
      </Grid>
    </Grid>
    <StyledBoxRow>
      <StyledSpan/>
      <Button onClick={() => props.setMode(MainMode.EDIT)}>Cancel</Button>
      <Button variant="contained" onClick={() => {
        props.klip.metadata.copyFrom(klipMetadata);
        props.klip.coverImageUrl = coverImageUrl;
        props.klip.scriptText = draftText;
        Klips.getInstance().addListItem(props.klip)
          .then(() => {
            BaseApp.CONTEXT.showToast("Saved.");
            props.setMode(MainMode.EDIT);
          })
      }}>Save</Button>
    </StyledBoxRow>
  </StyledContainer>;
}