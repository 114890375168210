import React, {ReactElement} from "react";
import {FORMGEN_STYLE_FLAG_NO_CONTAINER, FormGenMetadata, formGenMetadataKey} from "./formgen";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography
} from "@mui/material";
import {
  StyledBoxColumn,
  StyledBoxRow,
  StyledHorizontalStack,
  StyledMarkdown,
  StyledVerticalStack
} from "./StyledComponents";
import {
  DIVIDER,
  PAGE_FRAGMENT_HALF_WIDTH,
  PAGE_FRAGMENT_WIDTH,
  PD_MD,
  PD_SM,
  PD_XLG,
  PD_XSM,
  PD_XXSM,
  SZ_JUMBO,
  SZ_LG,
  SZ_XXLG
} from "./dimens";
import {DesktopDatePicker, DesktopDateTimePicker, DesktopTimePicker} from "@mui/x-date-pickers";
import {PROFILE_PHOTO_STYLE, PROFILE_PHOTO_STYLE_MD} from "./constants";
import {$KTS, Action, KeyTextString, UserProfilePhoto} from "./types";
import {ActionsDialogContent} from "./Dialogs";
import {FormGenAutocomplete} from "./FormGenAutocomplete";
import {BaseApp} from "./BaseApp";
import {FormGenIconSelect} from "./FormGenIconSelect";
import {validateEmail} from "./text_util";
import {colorRed, lightGray, mediumGray} from "./colors";
import {StoredDataFileUploadFragment} from "./StoredDateFileUploadFragment";
import {FormGenColorSelect} from "./FormGenColorSelect";
import {ContentCopyOutlined, ImageOutlined, UploadFileOutlined} from "@mui/icons-material";
import dayjs from "dayjs";
import {FormGenCountrySelect} from "./FormGenCountrySelect";
import {md5} from "./md5";
import {PathProps} from "../index";
import {FormGenRichText} from "./FormGenRichText";

export enum FormGenContainerMode {
  READ,
  CREATE,
  EDIT,
}

export type FormGenContainerProps = {
  path?: PathProps,
  style?: any,
  mode?: FormGenContainerMode,
  title?: string,
  description?: string,
  content: any,
  nestedObjectId?: string, // If this is set, only the nested object with id is shown.
  onContainerDidValidate?: (container: FormGenContainer, success: boolean) => void,
  layout?: "default" | "compact",
  disableAutoWrite?: boolean,
  autoSave?: boolean,
  saveDelay?: number,
  onContainerWillSave?: (container: FormGenContainer) => void,
  onContainerSave?: (container: FormGenContainer) => void,
}

export type FormGenContainerState = {
  contentItems?: FormGenContentItem[],
  _values: any,
  _errors?: any,
}

export type FormGenContentItem = {
  id: string,
  target: any,
  metadata: FormGenMetadata,
}

export type FormGenNestedObjectItem = {
  id: string,
  propertyKey: string,
  text: string,
}

export function formGenContentItemTransformText(contentItem: FormGenContentItem, str: string) {
  if (!contentItem.metadata.transformText) {
    return str;
  }
  if (contentItem.metadata.transformText === "uppercase") {
    return str.toUpperCase();
  } else if (contentItem.metadata.transformText === "lowercase") {
    return str.toUpperCase();
  } else {
    return contentItem.metadata.transformText(str);
  }
}

export interface FormGenLayoutSource {

  getMode(): FormGenContainerMode;

  getValue(contentItem: FormGenContentItem);

  setValue(contentItem: FormGenContentItem, value: any);

  getError(contentItem: FormGenContentItem): string | undefined | null;

  setError(contentItem: FormGenContentItem, error: string | undefined | null);
}

abstract class FormGenLayout {

  constructor(readonly path: PathProps, readonly source: FormGenLayoutSource) {
  }

  isReadonly(contentItem: FormGenContentItem): boolean {
    return (this.source.getMode() === FormGenContainerMode.EDIT && contentItem.metadata.noneditable)
      || contentItem.metadata.readonly
      || contentItem.metadata.readonlyFn?.(contentItem.target);
  }

  abstract renderBoolean(contentItem: FormGenContentItem, index: number);

  abstract renderString(contentItem: FormGenContentItem, index: number);

  abstract renderText(contentItem: FormGenContentItem, index: number);

  abstract renderRichText(contentItem: FormGenContentItem, index: number);

  abstract renderCopyable(contentItem: FormGenContentItem, index: number);

  abstract renderEmail(contentItem: FormGenContentItem, index: number);

  abstract renderNumber(contentItem: FormGenContentItem, index: number);

  abstract renderEnum(contentItem: FormGenContentItem, index: number);

  abstract renderDate(contentItem: FormGenContentItem, index: number);

  abstract renderTime(contentItem: FormGenContentItem, index: number);

  abstract renderDateTime(contentItem: FormGenContentItem, index: number);

  abstract renderDatenum(contentItem: FormGenContentItem, index: number);

  abstract renderTimenum(contentItem: FormGenContentItem, index: number);

  abstract renderDateTimenum(contentItem: FormGenContentItem, index: number);

  abstract renderObject(contentItem: FormGenContentItem, index: number);

  abstract renderCustom(contentItem: FormGenContentItem, index: number);

  abstract renderProfilePhoto(contentItem: FormGenContentItem, index: number);

  abstract renderIcon(contentItem: FormGenContentItem, index: number);

  abstract renderColor(contentItem: FormGenContentItem, index: number);

  abstract renderAutocomplete(contentItem: FormGenContentItem, index: number);

  abstract renderCountrySelect(contentItem: FormGenContentItem, index: number);

  abstract renderFile(contentItem: FormGenContentItem, index: number);

  abstract renderArrayString(contentItem: FormGenContentItem, index: number);

  abstract renderArrayAutocomplete(contentItem: FormGenContentItem, index: number);

  abstract renderArrayFile(contentItem: FormGenContentItem, index: number);
}

abstract class BaseFormGenLayout extends FormGenLayout {
  protected renderInContainer(contentItem: FormGenContentItem, children: ReactElement) {
    let description = null;
    if (contentItem.metadata.type === "enum") {
      description = contentItem.metadata.enumDescriptions?.find(keyText => keyText.key == this.source.getValue(contentItem))?.text;
    }
    if (!description) {
      description = contentItem.metadata.description;
    }
    const error = this.source.getError(contentItem);
    return <StyledVerticalStack
      label={contentItem.metadata.name}
      tooltip={contentItem.metadata.tooltip}
      style={{maxWidth: PAGE_FRAGMENT_WIDTH, margin: "auto"}}>
      {children}
      {description ? <Typography variant="body2" style={{marginTop: PD_XXSM}}>{description}</Typography> : null}
      {error ? <Typography variant="body2" style={{color: colorRed}}>{error}</Typography> : null}
    </StyledVerticalStack>;
  }
}

class ReadFormGenLayout extends BaseFormGenLayout {

  constructor(path: PathProps, source: FormGenLayoutSource) {
    super(path, source);
  }

  renderBoolean(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{Boolean(this.source.getValue(contentItem)) ? "True" : "False"}</Typography>
    );
  }

  renderString(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{this.source.getValue(contentItem) || "-"}</Typography>
    );
  }

  renderText(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{this.source.getValue(contentItem)}</Typography>
    );
  }

  renderRichText(contentItem: FormGenContentItem, index: number) {
    const markdownText = this.source.getValue(contentItem);
    if (!(markdownText?.length > 0)) {
      return <Typography>-</Typography>;
    }
    return this.renderInContainer(contentItem,
      <StyledMarkdown>
        {markdownText}
      </StyledMarkdown>
    );
  }

  renderCopyable(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{this.source.getValue(contentItem)}</Typography>
    );
  }

  renderEmail(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{this.source.getValue(contentItem)}</Typography>
    );
  }

  renderNumber(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{this.source.getValue(contentItem)}</Typography>
    );
  }

  renderEnum(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{contentItem.metadata.enumValues.find(val => val.key === this.source.getValue(contentItem))?.text || "-"}</Typography>
    );
  }

  renderDate(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{this.source.getValue(contentItem)}</Typography>
    );
  }

  renderTime(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{this.source.getValue(contentItem)}</Typography>
    );
  }

  renderDateTime(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      <Typography>{this.source.getValue(contentItem)}</Typography>
    );
  }

  renderDatenum(contentItem: FormGenContentItem, index: number) {
  }

  renderTimenum(contentItem: FormGenContentItem, index: number) {
  }

  renderDateTimenum(contentItem: FormGenContentItem, index: number) {
  }

  renderObject(contentItem: FormGenContentItem, index: number) {
  }

  renderCustom(contentItem: FormGenContentItem, index: number) {
  }

  renderProfilePhoto(contentItem: FormGenContentItem, index: number) {
  }

  renderIcon(contentItem: FormGenContentItem, index: number) {
  }

  renderColor(contentItem: FormGenContentItem, index: number) {
  }

  renderAutocomplete(contentItem: FormGenContentItem, index: number) {
  }

  renderCountrySelect(contentItem: FormGenContentItem, index: number) {
  }

  renderFile(contentItem: FormGenContentItem, index: number) {
    const url = this.source.getValue(contentItem);
    return this.renderInContainer(contentItem,
      <Card style={{
        ...PROFILE_PHOTO_STYLE_MD,
        backgroundColor: lightGray,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        {url
          ? <img src={url} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
          : <ImageOutlined style={{width: "50%", height: "50%", color: mediumGray, opacity: 0.5}}/>}
      </Card>
    );
  }

  renderArrayString(contentItem: FormGenContentItem, index: number) {
    const arrayString: string[] = this.source.getValue(contentItem);
    return this.renderInContainer(contentItem,
      arrayString?.length > 0
        ? <StyledBoxColumn>
          {arrayString?.map(item => <Typography>{item}</Typography>)}
        </StyledBoxColumn>
        : <Typography>-</Typography>
    );
  }

  renderArrayAutocomplete(contentItem: FormGenContentItem, index: number) {
  }

  renderArrayFile(contentItem: FormGenContentItem, index: number) {
  }
}

class DefaultFormGenLayout extends BaseFormGenLayout {

  constructor(path: PathProps, source: FormGenLayoutSource) {
    super(path, source);
  }

  renderBoolean(contentItem: FormGenContentItem, index: number): ReactElement {
    return this.renderInContainer(contentItem, (
      <Switch
        disabled={this.isReadonly(contentItem)}
        style={{flexGrow: 1}}
        checked={this.source.getValue(contentItem)}
        value={this.source.getValue(contentItem)}
        onChange={event => this.source.setValue(contentItem, event.target.checked)}
        id={contentItem.id}
      />
    ));
  }

  renderString(contentItem: FormGenContentItem, index: number): ReactElement {
    return this.renderInContainer(contentItem, (
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <TextField
          inputProps={{maxLength: contentItem.metadata.maxLength}}
          size="small"
          multiline={contentItem.metadata.multilineStringRows > 1}
          rows={contentItem.metadata.multilineStringRows}
          autoFocus={index === 0}
          disabled={this.isReadonly(contentItem)}
          style={{width: SZ_LG, flexGrow: 1}}
          value={this.source.getValue(contentItem) || ""}
          onChange={event => this.source.setValue(contentItem, formGenContentItemTransformText(contentItem, event.target.value))}
          id={contentItem.id} variant="outlined" required/>
      </Box>
    ));
  }

  private textContentHeight(contentItem: FormGenContentItem) {
    switch (contentItem.metadata.size) {
      case "small":
        return SZ_XXLG;
      case "large":
        return SZ_JUMBO * 1.5;
    }
    return SZ_JUMBO;
  }

  renderText(contentItem: FormGenContentItem, index: number): ReactElement {
    return this.renderInContainer(contentItem, (
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <TextareaAutosize
          placeholder={contentItem.metadata.placeholder}
          disabled={this.isReadonly(contentItem)}
          style={{
            width: SZ_LG,
            height: this.textContentHeight(contentItem),
            flexGrow: 1,
            resize: "vertical",
            overflow: "auto",
            fontFamily: "sans-serif",
            fontSize: "100%",
            padding: PD_SM
          }}
          value={this.source.getValue(contentItem) || ""}
          onChange={event => this.source.setValue(contentItem, event.target.value)}
          id={contentItem.id}
          required/>
      </Box>
    ));
  }

  renderRichText(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem, (
      <FormGenRichText
        placeholder={contentItem.metadata.placeholder}
        disabled={this.isReadonly(contentItem)}
        contentHeight={this.textContentHeight(contentItem)}
        contentItem={contentItem}
        index={index}
        source={this.source}/>
    ));
  }

  renderCopyable(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem, (
      <Box display="flex" flexDirection="row">
        <Typography
          id={contentItem.id}>
          {this.source.getValue(contentItem)}
          <Button style={{marginLeft: PD_SM}}>
            <ContentCopyOutlined/>
          </Button>
        </Typography>
      </Box>
    ));
  }

  renderEmail(contentItem: FormGenContentItem, index: number): ReactElement {
    return this.renderInContainer(contentItem, (
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <TextField
          size="small"
          disabled={this.isReadonly(contentItem)}
          style={{width: SZ_LG, flexGrow: 1}}
          value={this.source.getValue(contentItem)}
          onChange={event => this.source.setValue(contentItem, event.target.value)}
          onBlur={() => {
            const error = !validateEmail(this.source.getValue(contentItem)) ? "Please enter a valid email" : null;
            this.source.setError(contentItem, error);
          }}
          id={contentItem.id} variant="outlined"
          required/>
      </Box>
    ));
  }

  renderNumber(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem, (
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <TextField
          inputProps={{maxLength: contentItem.metadata.maxLength}}
          size="small"
          type="number"
          multiline={contentItem.metadata.multilineStringRows > 1}
          rows={contentItem.metadata.multilineStringRows}
          autoFocus={index === 0}
          disabled={this.isReadonly(contentItem)}
          style={{width: SZ_LG, flexGrow: 1}}
          value={this.source.getValue(contentItem)}
          onChange={event => this.source.setValue(contentItem, Number.parseFloat(event.target.value))}
          id={contentItem.id} variant="outlined" required/>
      </Box>
    ));
  }

  renderEnum(contentItem: FormGenContentItem, index: number): ReactElement {
    switch (contentItem.metadata.enumVariant) {
      case "button":
        return this.renderEnumButton(contentItem);
      case "select":
      default:
        return this.renderEnumSelect(contentItem);
    }
  }

  private renderEnumButton(contentItem: FormGenContentItem) {
    let selectedValue = this.source.getValue(contentItem);
    if (!selectedValue && contentItem.metadata.enumDefaultKey) {
      this.source.setValue(contentItem, contentItem.metadata.enumDefaultKey);
      selectedValue = contentItem.metadata.enumDefaultKey;
    }
    return this.renderInContainer(contentItem, (
      <Box display="flex" flexDirection="row" flexWrap="wrap" style={{gap: PD_SM}}>
        {contentItem.metadata.enumValues.map(value => {
          const selected = selectedValue === value.key;
          return <Button onClick={() => this.source.setValue(contentItem, value.key)}
                         variant={(selected ? "contained" : "text")}
                         style={{background: selected ? null : lightGray}}>{value.text}</Button>;
        })}
      </Box>
    ));
  }

  private renderEnumSelect(contentItem: FormGenContentItem) {
    let selectedValue = this.source.getValue(contentItem);
    if (contentItem.metadata.enumMulti && (selectedValue === undefined || selectedValue === null)) {
      this.source.setValue(contentItem, []);
      selectedValue = [];
    }
    if (!selectedValue && contentItem.metadata.enumDefaultKey) {
      this.source.setValue(contentItem, contentItem.metadata.enumDefaultKey);
      selectedValue = contentItem.metadata.enumDefaultKey;
    }
    const enumValues: KeyTextString[] = [];
    if (!contentItem.metadata.enumMulti && !contentItem.metadata.enumDefaultKey) {
      enumValues.push($KTS("", "--"));
    }
    enumValues.push(...contentItem.metadata.enumValues);
    return this.renderInContainer(contentItem, (
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <FormControl variant="outlined">
          <Select id={contentItem.id}
                  multiple={contentItem.metadata.enumMulti}
                  size="small"
                  sx={{width: SZ_JUMBO}}
                  disabled={this.isReadonly(contentItem)}
                  value={selectedValue}
                  onChange={event => this.source.setValue(contentItem, event.target.value)}>
            {enumValues.map(value => {
              return <MenuItem value={value.key}>
                {value.text}
              </MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
    ));
  }

  renderDate(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopDatePicker
          sx={{minWidth: PAGE_FRAGMENT_HALF_WIDTH}}
          value={this.source.getValue(contentItem)}
          onChange={date => this.source.setValue(contentItem, new Date(date))}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderTime(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopTimePicker
          sx={{minWidth: PAGE_FRAGMENT_HALF_WIDTH}}
          value={this.source.getValue(contentItem)}
          onChange={date => this.source.setValue(contentItem, new Date(date))}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderDateTime(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopDateTimePicker
          sx={{minWidth: PAGE_FRAGMENT_HALF_WIDTH}}
          value={this.source.getValue(contentItem)}
          onChange={date => this.source.setValue(contentItem, new Date(date))}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderDatenum(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopDatePicker
          slotProps={{textField: {size: "small"}}}
          sx={{minWidth: PAGE_FRAGMENT_HALF_WIDTH}}
          value={dayjs(this.source.getValue(contentItem))}
          onChange={date => this.source.setValue(contentItem, date.toDate().getTime())}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderTimenum(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopTimePicker
          sx={{minWidth: PAGE_FRAGMENT_HALF_WIDTH}}
          value={new Date(this.source.getValue(contentItem))}
          onChange={date => this.source.setValue(contentItem, new Date(date).getTime())}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderDateTimenum(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopDateTimePicker
          sx={{minWidth: PAGE_FRAGMENT_HALF_WIDTH}}
          value={new Date(this.source.getValue(contentItem))}
          onChange={date => this.source.setValue(contentItem, new Date(date).getTime())}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderObject(contentItem: FormGenContentItem, index: number): ReactElement {
    return <>
      <Typography variant="h6" style={{marginTop: PD_SM}}>{contentItem.metadata.name}</Typography>
      {contentItem.metadata.description ?
        <Typography variant="body2" style={{marginTop: -16}}>{contentItem.metadata.description}</Typography> : null}
    </>;
  }

  renderCustom(contentItem: FormGenContentItem, index: number): ReactElement {
    if (contentItem.metadata.styleFlags & FORMGEN_STYLE_FLAG_NO_CONTAINER) {
      return contentItem.metadata.customRenderer?.(contentItem.target, value => this.source.setValue(contentItem, value), this.path);
    }
    return this.renderInContainer(contentItem, (
      <Box style={{display: "flex", flexDirection: "column"}}>
        {contentItem.metadata.customRenderer?.(contentItem.target, value => this.source.setValue(contentItem, value), this.path)}
      </Box>
    ));
  }

  renderProfilePhoto(contentItem: FormGenContentItem, index: number): ReactElement {
    return this.renderInContainer(contentItem, (
      <Box style={{display: "flex", alignItems: "flex-start"}}>
        <Box style={{display: "flex", flexDirection: "column", alignItems: "center", gap: PD_XSM}}>
          <IconButton onClick={() => this.onChangeProfilePhoto(contentItem)}>
            <Avatar variant="rounded" style={{...PROFILE_PHOTO_STYLE}}
                    src={this.source.getValue(contentItem) || UserProfilePhoto(null)}/>
          </IconButton>
          <Typography variant="caption">Tap to change</Typography>
        </Box>
      </Box>
    ));
  }

  private onChangeProfilePhoto(contentItem: FormGenContentItem) {
    BaseApp.CONTEXT.showDialog(null, () => this.showChangeProfilePhotoOptions(contentItem))
  }

  private showChangeProfilePhotoOptions(contentItem: FormGenContentItem) {
    const actions: Action[] = [
      new Action("Upload", () => {
        BaseApp.CONTEXT.showDialog(null, () => <StoredDataFileUploadFragment
          mimeTypes={["image/*"]}
          storedDataSource={"profile-photos"}
          onSelectionChanged={(urls: string[]) => {
            this.source.setValue(contentItem, urls[0]);
          }}/>);
      }),
      new Action("Remove", () => {
      }),
    ];
    return <ActionsDialogContent actions={actions}/>;
  }

  renderIcon(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem, (
      <FormGenIconSelect contentItem={contentItem} index={index} source={this.source}/>
    ));
  }

  renderColor(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem, (
      <FormGenColorSelect contentItem={contentItem} index={index} source={this.source}/>
    ));
  }

  renderAutocomplete(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem, (
      <FormGenAutocomplete contentItem={contentItem} index={index} source={this.source}/>
    ));
  }

  renderCountrySelect(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem, (
      <FormGenCountrySelect contentItem={contentItem} index={index} source={this.source}/>
    ));
  }

  private renderFileInternal(metadata: FormGenMetadata, getValue: () => string, setValue: (url: string) => void): ReactElement {
    const url = getValue();
    return <Box style={{display: "flex", alignItems: "flex-start"}}>
      <StyledBoxColumn style={{alignItems: "center"}}>
        <ButtonBase onClick={() => this.onChangeFile(metadata, getValue, setValue)}>
          <Card style={{
            ...PROFILE_PHOTO_STYLE_MD,
            backgroundColor: lightGray,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            {url
              ? <img src={url} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
              : <UploadFileOutlined style={{width: "50%", height: "50%", color: mediumGray, opacity: 0.5}}/>}
          </Card>
        </ButtonBase>
        <Typography variant="caption">Tap to change</Typography>
      </StyledBoxColumn>
    </Box>;
  }

  private onChangeFile(metadata: FormGenMetadata, getValue: () => string, setValue: (url: string) => void) {
    BaseApp.CONTEXT.showDialog(null, () => this.showChangeFileOptions(metadata, getValue, setValue))
  }

  private showChangeFileOptions(metadata: FormGenMetadata, getValue: () => string, setValue: (url: string) => void) {
    const actions: Action[] = [
      new Action("Upload", () => {
        BaseApp.CONTEXT.showDialog(null, () => <StoredDataFileUploadFragment
          mimeTypes={metadata.fileMimeTypes}
          select
          storedDataSource={metadata.fileStoredDataSource || "files"}
          onSelectionChanged={(urls: string[]) => {
            setValue(urls[0]);
          }}/>);
      }),
      new Action("Remove", () => {
      }),
      new Action("Copy URL", () => {
        navigator.clipboard.writeText(getValue());
        BaseApp.CONTEXT.showToast("Copied.");
      }),
    ];
    return <ActionsDialogContent actions={actions}/>;
  }

  renderFile(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem,
      this.renderFileInternal(contentItem.metadata, () => this.source.getValue(contentItem), url => this.source.setValue(contentItem, url))
    );
  }

  renderArrayString(contentItem: FormGenContentItem, index: number) {
    return this.renderInContainer(contentItem, (
      <StyledBoxColumn>
        {(this.source.getValue(contentItem) as Array<string>)?.map(value =>
          <Box display="flex" flexDirection="row"
               style={{gap: PD_SM}}>
            <TextField
              disabled
              style={{width: SZ_LG, flexGrow: 1}}
              value={value}
              id={contentItem.id} variant="outlined" required/>
            <Button>Remove</Button>
          </Box>
        )}
        <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
          <TextField
            style={{width: SZ_LG, flexGrow: 1}}
            onChange={event => {
            }}
            id={contentItem.id} variant="outlined" required/>
          <Button>Add</Button>
        </Box>
      </StyledBoxColumn>
    ));
  }

  renderArrayAutocomplete(contentItem: FormGenContentItem, index: number) {
  }

  renderArrayFile(contentItem: FormGenContentItem, index: number) {
    const urls: string[] = [];
    const values: string[] = this.source.getValue(contentItem) as Array<string>;
    if (values?.length > 0) {
      urls.push(...(values.filter(value => value?.trim()?.length > 0)));
    }
    urls.push("");
    return this.renderInContainer(contentItem, (
      <StyledBoxRow>
        {urls.map((_, index) =>
          this.renderFileInternal(
            contentItem.metadata,
            () => this.source.getValue(contentItem)?.[index],
            url => {
              urls[index] = url;
              this.source.setValue(contentItem, urls);
            })
        )}
      </StyledBoxRow>
    ));
  }
}

class CompactFormGenLayout extends FormGenLayout {

  constructor(path: PathProps, source: FormGenLayoutSource) {
    super(path, source);
  }

  renderBoolean(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledHorizontalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <Switch
          disabled={this.isReadonly(contentItem)}
          size="small"
          style={{flexGrow: 1}}
          value={this.source.getValue(contentItem)}
          onChange={event => this.source.setValue(contentItem, event.target.checked)}
          id={contentItem.id}
        />
      </Box>
    </StyledHorizontalStack>
  }

  renderString(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledHorizontalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <TextField
          inputProps={{maxLength: contentItem.metadata.maxLength}}
          disabled={this.isReadonly(contentItem)}
          size="small"
          style={{width: SZ_LG, flexGrow: 1}}
          value={this.source.getValue(contentItem)}
          onChange={event => this.source.setValue(contentItem, formGenContentItemTransformText(contentItem, event.target.value))}
          id={contentItem.id} variant="outlined" required/>
      </Box>
    </StyledHorizontalStack>
  }

  renderText(contentItem: FormGenContentItem, index: number) {
  }

  renderRichText(contentItem: FormGenContentItem, index: number) {
  }

  renderCopyable(contentItem: FormGenContentItem, index: number) {
  }

  renderEmail(contentItem: FormGenContentItem, index: number) {
  }

  renderNumber(contentItem: FormGenContentItem, index: number) {
  }

  renderEnum(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <FormControl variant="outlined">
          <Select id={contentItem.id}
                  disabled={this.isReadonly(contentItem)}
                  value={this.source.getValue(contentItem)}
                  onChange={event => this.source.setValue(contentItem, event.target.value)}>
            {[$KTS("", ""), ...contentItem.metadata.enumValues].map(value => <MenuItem
              value={value.key}>{value.text}</MenuItem>)}
          </Select>
        </FormControl>
      </Box>
    </StyledVerticalStack>;
  }

  renderDate(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopDatePicker
          value={this.source.getValue(contentItem)}
          onChange={date => this.source.setValue(contentItem, new Date(date))}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderTime(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopTimePicker
          value={this.source.getValue(contentItem)}
          onChange={date => this.source.setValue(contentItem, new Date(date))}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderDateTime(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopDateTimePicker
          value={this.source.getValue(contentItem)}
          onChange={date => this.source.setValue(contentItem, new Date(date))}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderDatenum(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopDatePicker
          value={new Date(this.source.getValue(contentItem))}
          onChange={date => this.source.setValue(contentItem, new Date(date).getTime())}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderTimenum(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopTimePicker
          value={new Date(this.source.getValue(contentItem))}
          onChange={date => this.source.setValue(contentItem, new Date(date).getTime())}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderDateTimenum(contentItem: FormGenContentItem, index: number): ReactElement {
    return <StyledVerticalStack label={contentItem.metadata.name}>
      <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
        <DesktopDateTimePicker
          value={new Date(this.source.getValue(contentItem))}
          onChange={date => this.source.setValue(contentItem, new Date(date).getTime())}/>
      </Box>
    </StyledVerticalStack>;
  }

  renderObject(contentItem: FormGenContentItem, index: number) {
    return <Typography variant="h6" style={{marginTop: PD_SM}}>{contentItem.metadata.name}</Typography>;
  }

  renderCustom(contentItem: FormGenContentItem, index: number) {
  }

  renderProfilePhoto(contentItem: FormGenContentItem, index: number) {
  }

  renderIcon(contentItem: FormGenContentItem, index: number) {
  }

  renderColor(contentItem: FormGenContentItem, index: number) {
  }

  renderAutocomplete(contentItem: FormGenContentItem, index: number) {
  }

  renderCountrySelect(contentItem: FormGenContentItem, index: number) {
  }

  renderFile(contentItem: FormGenContentItem, index: number) {
  }

  renderArrayString(contentItem: FormGenContentItem, index: number) {
  }

  renderArrayAutocomplete(contentItem: FormGenContentItem, index: number) {
  }

  renderArrayFile(contentItem: FormGenContentItem, index: number) {
  }
}

export class FormGenContainer extends React.Component<FormGenContainerProps, FormGenContainerState> implements FormGenLayoutSource {

  private layout: FormGenLayout;
  private layoutGap: number;

  private scheduledSave: any;

  constructor(props: FormGenContainerProps, context: any) {
    super(props, context);
    this.state = {
      _values: {},
    }
  }

  getMode(): FormGenContainerMode {
    return this.props.mode || FormGenContainerMode.CREATE;
  }

  private async update() {
    const contentItems = this.findContentItems(this.props.content);
    for (const item of contentItems) {
      if (item.metadata._asyncInit) {
        await item.metadata._asyncInit(item.metadata);
      }
    }
    const _values = {};
    contentItems.forEach(contentItem => _values[contentItem.id] = contentItem.target[contentItem.metadata._propertyKey]);
    this.setState({
      contentItems: contentItems,
      _values: _values,
    });
    if (this.props.mode === FormGenContainerMode.READ) {
      this.layout = new ReadFormGenLayout(this.props.path, this);
      this.layoutGap = PD_XLG;
    } else {
      switch (this.props.layout || "default") {
        default:
        case "default":
          this.layout = new DefaultFormGenLayout(this.props.path, this);
          this.layoutGap = PD_XLG;
          break;
        case "compact":
          this.layout = new CompactFormGenLayout(this.props.path, this);
          this.layoutGap = PD_XSM;
          break;
      }
    }
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps: Readonly<FormGenContainerProps>, prevState: Readonly<FormGenContainerState>, snapshot?: any) {
    if (prevProps.content !== this.props.content
      || prevProps.mode !== this.props.mode
      || prevProps.layout !== this.props.layout
      || prevProps.nestedObjectId !== this.props.nestedObjectId) {
      this.update();
    }
  }

  save() {
    this.saveItems(this.state.contentItems);
  }

  private saveItems(contentItems: FormGenContentItem[]) {
    contentItems?.forEach(contentItem => {
      if (contentItem.metadata.type === "object") {
        this.saveItems(this.findContentItems(contentItem.target[contentItem.metadata._propertyKey]));
        return;
      }
      return this.saveItem(contentItem, this.state._values[contentItem.id]);
    })
  }

  private saveItem(contentItem: FormGenContentItem, value: any) {
    contentItem.target[contentItem.metadata._propertyKey] = value;
    this.scheduleSave();
  }

  private scheduleSave() {
    this.props.onContainerWillSave?.(this);
    if (this.props.autoSave) {
      clearTimeout(this.scheduledSave);
      this.scheduledSave = setTimeout(() => this.props.onContainerSave?.(this), this.props.saveDelay || 1000);
    }
  }

  static getNestedObjectItems(object: any): FormGenNestedObjectItem[] {
    return this.findContentItemsInternal(object, true)
      .filter((item: FormGenContentItem) => item.metadata.type === "object")
      .map((item: FormGenContentItem) => ({
        id: item.id,
        propertyKey: item.metadata._propertyKey,
        text: item.metadata.name
      }));
  }

  private findContentItems(object: any): FormGenContentItem[] {
    if (this.props.nestedObjectId) {
      const nestedObjectItems = FormGenContainer.getNestedObjectItems(object);
      return FormGenContainer.findContentItemsInternal(object[nestedObjectItems.find(item => item.id === this.props.nestedObjectId).propertyKey]);
    }
    return FormGenContainer.findContentItemsInternal(object);
  }

  private static findContentItemsInternal(object: any, norecursive?: boolean): FormGenContentItem[] {
    let contentItems: FormGenContentItem[] = [];
    // if (!object) {
    //   return contentItems;
    // }
    if (object instanceof Array) {
      const array: any[] = object;
      array.forEach(item => contentItems.push(...this.findContentItemsInternal(item)));
    } else {
      for (let propertyKey of Object.getOwnPropertyNames(object)) {
        if (propertyKey.startsWith("__formgen_")) {
          continue;
        }
        //@ts-ignore
        let metadata: FormGenMetadata = object[formGenMetadataKey(propertyKey)];
        if (!metadata) {
          continue;
        }
        contentItems.push({id: md5(propertyKey), target: object, metadata: metadata});
        if (metadata.type === "object" && !norecursive) {
          contentItems.push(...this.findContentItemsInternal(object[propertyKey]));
        }
      }
    }
    contentItems.sort((a, b) => a.metadata.index - b.metadata.index);
    return contentItems;
  }

  componentWillUnmount() {
    this.setState({
      contentItems: null,
    });
  }

  render() {
    if (!this.state.contentItems) {
      return null;
    }
    return <Box style={{
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: PD_MD,
      gap: this.layoutGap,
      //maxWidth: PAGE_FRAGMENT_WIDTH,
      ...this.props.style
    }}>
      {this.props.title ?
        <Typography style={{fontWeight: "bold", fontSize: "100%"}}>{this.props.title}</Typography> : null}
      {this.props.description ? <Typography variant="body2">{this.props.description}</Typography> : null}
      {this.state.contentItems.map((contentItem, index) => {
        return <>
          {contentItem.metadata.separator === "above" ?
            <div style={{marginTop: PD_SM, height: 1, borderBottom: DIVIDER}}/> : null}
          {contentItem.metadata.sectionTitle ?
            <Typography variant="h6" style={{marginTop: PD_SM}}>{contentItem.metadata.sectionTitle}</Typography> : null}
          {this.renderContentItem(contentItem, index)}
          {contentItem.metadata.separator === "below" ?
            <div style={{marginTop: PD_XSM, height: 1, borderBottom: DIVIDER}}/> : null}
        </>;
      })}
    </Box>
  }

  private renderContentItem(contentItem: FormGenContentItem, index: number) {
    switch (contentItem.metadata.type) {
      case "boolean":
        return this.layout.renderBoolean(contentItem, index);
      case "string":
        return this.layout.renderString(contentItem, index);
      case "text":
        return this.layout.renderText(contentItem, index);
      case "rich_text":
        return this.layout.renderRichText(contentItem, index);
      case "copyable":
        return this.layout.renderCopyable(contentItem, index);
      case "email":
        return this.layout.renderEmail(contentItem, index);
      case "number":
        return this.layout.renderNumber(contentItem, index);
      case "enum":
        return this.layout.renderEnum(contentItem, index);
      case "date":
        return this.layout.renderDate(contentItem, index);
      case "time":
        return this.layout.renderTime(contentItem, index);
      case "datetime":
        return this.layout.renderDateTime(contentItem, index);
      case "datenum":
        return this.layout.renderDatenum(contentItem, index);
      case "timenum":
        return this.layout.renderTimenum(contentItem, index);
      case "datetimenum":
        return this.layout.renderDateTimenum(contentItem, index);
      case "object":
        return this.layout.renderObject(contentItem, index);
      case "custom":
        return this.layout.renderCustom(contentItem, index);
      case "profile_photo":
        return this.layout.renderProfilePhoto(contentItem, index);
      case "icon":
        return this.layout.renderIcon(contentItem, index);
      case "color":
        return this.layout.renderColor(contentItem, index);
      case "autocomplete":
        return this.layout.renderAutocomplete(contentItem, index);
      case "country_select":
        return this.layout.renderCountrySelect(contentItem, index);
      case "file":
        return this.layout.renderFile(contentItem, index);
      case "array_string":
        return this.layout.renderArrayString(contentItem, index);
      case "array_autocomplete":
        return this.layout.renderArrayAutocomplete(contentItem, index);
      case "array_file":
        return this.layout.renderArrayFile(contentItem, index);
    }
    return null;
  }

  getValue(contentItem: FormGenContentItem) {
    return this.state._values[contentItem.id];
  }

  setValue(contentItem: FormGenContentItem, value: any) {
    if (contentItem.metadata.onSetValue) {
      contentItem.metadata.onSetValue(contentItem.target, value);
    }
    if (contentItem.metadata.onSetTransformValue) {
      value = contentItem.metadata.onSetTransformValue(contentItem.target, value);
    }
    this.setState({
      _values: {
        ...this.state._values,
        [contentItem.id]: value,
      },
    });
    if (!this.props.disableAutoWrite) {
      this.saveItem(contentItem, value);
      if (contentItem.metadata.onValueSet) {
        contentItem.metadata.onValueSet(contentItem.target, value);
      }
    }
  }

  getError(contentItem: FormGenContentItem) {
    return this.state._errors?.[contentItem.id];
  }

  setError(contentItem: FormGenContentItem, error: string | undefined | null) {
    const errors = {
      ...this.state._errors,
      [contentItem.id]: error,
    };
    this.setState({
      _errors: errors,
    });
    this.props.onContainerDidValidate?.(this, Object.getOwnPropertyNames(errors).findIndex(name => Boolean(errors[name])) < 0);
  }
}
