import {JsonObject} from "../common/json/json-object";
import {JsonProperty} from "../common/json/json-property";
import {v4 as uuid} from "uuid";
import {BaseListItem, BaseListItemsLoader} from "../shared/types";
import {JSON_OBJECT} from "../shared/json/helpers";
import {getMemberAuth} from "../shared/auth";
import {FormGen} from "../shared/formgen";

@JsonObject()
export class KlipMetadata {

  @FormGen({name: "Title", type: "string"})
  @JsonProperty()
  title: string = "Untitled klip";

  @FormGen({name: "Description", type: "rich_text"})
  @JsonProperty()
  description: string;

  @FormGen({
    name: "Draft",
    type: "boolean",
    description: "If set, this klip won't be visible to others but will be saved so that you can modify it later."
  })
  @JsonProperty()
  draft: boolean;

  copyFrom(metadata: KlipMetadata): KlipMetadata {
    this.title = metadata.title;
    this.description = metadata.description;
    this.draft = metadata.draft;
    return this;
  }

  clone(): KlipMetadata {
    return new KlipMetadata().copyFrom(this);
  }
}

@JsonObject()
export class Klip extends BaseListItem {

  static createNew() {
    const memberId = getMemberAuth().getMemberId();
    return new Klip(uuid(), memberId, Date.now());
  }

  @JsonProperty()
  readonly metadata: KlipMetadata = new KlipMetadata();

  @JsonProperty()
  coverImageUrl?: string;

  @JsonProperty()
  scriptText?: string;

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export function KlipToString(klip: Klip) {
  return KlipTimeToString(klip.created);
}

export function KlipTimeToString(time: number) {
  return new Date(time).toLocaleString("en-us", {
    dateStyle: "medium",
    timeStyle: "short"
  })
}

export class Klips extends BaseListItemsLoader<Klip> {

  private static instance;

  static getInstance(): Klips {
    if (!this.instance) {
      this.instance = new Klips();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "klips";
  }

  protected deserializeItem(value: any): Klip {
    return JSON_OBJECT.deserializeObject(value, Klip);
  }

  protected serializeItem(item: Klip): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Klip, item2: Klip): number {
    // Reverse chronological
    return item2.created - item1.created;
  }
}