import {
  BORDER_RADIUS,
  BORDER_RADIUS_LARGE,
  BORDER_RADIUS_SMALL,
  DIVIDER,
  DIVIDER_COLOR,
  PAGE_FRAGMENT_HALF_WIDTH,
  PAGE_FRAGMENT_LG_WIDTH,
  PAGE_FRAGMENT_SM_WIDTH,
  PAGE_FRAGMENT_WIDTH,
  PAGE_FRAGMENT_XLG_WIDTH,
  PD,
  PD_MD,
  PD_SM,
  PD_XSM,
  PD_XXLG,
  PD_XXSM,
  PD_XXXSM,
  SZ_JUMBO,
  SZ_SM,
  SZ_SSM,
  SZ_THUMBNAIL,
  SZ_XXLG
} from "./dimens";
import {
  Badge,
  Box,
  BoxProps,
  Button,
  ButtonBase,
  ButtonProps,
  Card,
  CircularProgress,
  IconButton,
  styled,
  SvgIcon,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme
} from "@mui/material";
import React, {ReactElement, ReactNode, Ref, useEffect, useState} from "react";
import {Action, EmptyConfig, LocalFile, UserProfilePhoto} from "./types";
import {brightPaletteColors, colorHighlightAlt, colorRed, mediumGray, nightGray, white} from "./colors";
import {Link} from "react-router-dom";
import {DesktopFile} from "./files";
import {createInput, InputSpec} from "./BaseFragment";
import {ICON_BUTTON_LIGHT_XSM_STYLE, ICON_BUTTON_XSM_STYLE_PD} from "./constants";
import {BaseApp} from "./BaseApp";
import {BugReportOutlined, InfoOutlined, MoreVertRounded} from "@mui/icons-material";
import {md5_code} from "./md5";
import {Member} from "./entities";
import Markdown, {Options} from "react-markdown";

// import defaultImage from "res/images/default_photo_album.png";

export function StyledSpan(props: { style?: any }) {
  return <span style={{flexGrow: 1, ...props.style}}/>
}

const STYLED_AVATAR_SIZES = new Map<string, number>([
  ["xlg", 72],
  ["lg", 64],
  ["md", 48],
  ["sm", 24],
  ["xsm", 16],
]);

export function StyledAvatar(props: { member: Member, size?: "xlg" | "lg" | "md" | "sm" | "xsm" }) {
  const size = STYLED_AVATAR_SIZES.get(props.size || "md");
  return <Card style={{width: size, height: size, flexShrink: 0}}>
    <img style={{width: "100%", height: "100%"}} src={UserProfilePhoto(props.member?.user)}/>
  </Card>;
}

export function StyledVisible(props: { visible: boolean, style?: any, children: any }) {
  return <Box style={{display: props.visible ? "flex" : "none", ...props.style}}>
    {props.children}
  </Box>;
}

export function StyledSwatch(props: { color: string, onClick?: (color: string) => void }) {
  return <Button disabled={!Boolean(props.onClick)} style={{width: SZ_SM, height: SZ_SSM, backgroundColor: props.color}}
                 onClick={() => props.onClick(props.color)}/>
}

export function StyledDeferred(props: { fallback: ReactElement, defer: () => Promise<ReactNode> }) {
  const [deferred, setDeferred] = useState(undefined);
  useEffect(() => {
    props.defer().then(rendered => setDeferred(rendered));
  }, []);
  return deferred === undefined
    ? props.fallback
    : deferred;
}

export function StyledVerticalDivider() {
  return <div
    style={{width: 1, marginLeft: PD_SM, marginRight: PD_SM, backgroundColor: DIVIDER_COLOR, alignSelf: "stretch"}}/>;
}

export function StyledHorizontalDivider() {
  return <div
    style={{height: 1, marginLeft: PD_SM, marginRight: PD_SM, backgroundColor: DIVIDER_COLOR, alignSelf: "stretch"}}/>;
}

export function StyledBoxRow(props: BoxProps) {
  return <Box style={{display: "flex", gap: PD_SM, ...props.style}}>
    {props.children}
  </Box>;
}

export function StyledBoxRowWithLabel(props: { label: string } & BoxProps) {
  return <StyledBoxColumn>
    <Typography variant="body2" style={{fontWeight: "bold"}}>{props.label}</Typography>
    <Box id={props.id} style={{display: "flex", gap: PD_SM, ...props.style}}>
      {props.children}
    </Box>
  </StyledBoxColumn>;
}

export function StyledBoxColumnWithLabel(props: { label: string } & BoxProps) {
  return <StyledBoxColumn>
    <Typography variant="body2" style={{fontWeight: "bold"}}>{props.label}</Typography>
    <Box id={props.id} style={{display: "flex", flexDirection: "column", gap: PD_SM, ...props.style}}>
      {props.children}
    </Box>
  </StyledBoxColumn>;
}

export function StyledBoxColumnWithBackgroundCoverImage(props: { backgroundCoverImage?: string } & BoxProps) {
  return <StyledBoxColumn style={{position: "relative", ...props.style}}>
    {props.backgroundCoverImage
      ? <img
        style={{
          zIndex: 0,
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          opacity: .25
        }}
        src={props.backgroundCoverImage}/>
      : null}
    {props.children}
  </StyledBoxColumn>;
}

export function StyledBoxColumn(props: BoxProps & { gutter?: boolean }) {
  return <Box style={{
    display: "flex",
    flexDirection: "column",
    gap: PD_MD,
    paddingLeft: props.gutter ? PD_MD : 0,
    paddingRight: props.gutter ? PD_MD : 0,
    position: "relative",
    ...props.style
  }}>
    {props.children}
  </Box>;
}

export function StyledBoxColumnCard(props: BoxProps & {
  title?: string,
  titleComponent?: ReactElement,
  iconType?: typeof SvgIcon,
  text?: string,
  toolbar?: ReactElement,
  highlight?: boolean,
  highlightColor?: string
}) {
  const theme = useTheme();
  let style = {};
  if (props.highlight || props.highlightColor) {
    style = {
      ...style,
      borderTop: (props.highlightColor || theme.palette.primary.main) + " 4px solid",
    }
  }
  return <Card style={style}>
    <Box style={{
      display: "flex",
      flexDirection: "column",
      gap: PD_SM,
      ...props.style
    }}>
      {props.iconType || props.title || props.text
        ? <StyledBoxColumn style={{borderBottom: DIVIDER}}>
          <StyledBoxRow style={{alignItems: "center", paddingLeft: PD_SM, paddingRight: PD_SM, height: 48}}>
            {props.iconType ? <props.iconType/> : null}
            {Boolean(props.titleComponent)
              ? <Box style={{display: "flex", flexGrow: 1}}>
                {props.titleComponent}
              </Box>
              : null}
            {props.title
              ? <>
                <Typography variant="h6">{props.title}</Typography>
                <StyledSpan/>
              </>
              : null}
            {props.toolbar}
          </StyledBoxRow>
          {props.text
            ? <Typography style={{color: nightGray, paddingLeft: PD_SM, paddingRight: PD_SM}}>{props.text}</Typography>
            : null}
        </StyledBoxColumn>
        : null}
      <StyledBoxColumn style={{flexGrow: 1, padding: PD_SM, marginTop: -PD_SM}}>
        {props.children}
      </StyledBoxColumn>
    </Box>
  </Card>;

}

export function StyledTaggedText(props: TypographyProps & {}) {
  const text = props.children as string;
  if (!text || !(typeof text === "string")) {
    return null;
  }
  let transformed: ReactElement[] = [];
  let start, end = -1;
  while ((start = text.indexOf("[", end + 1)) >= 0) {
    transformed.push(<>{text.substring(end + 1, start)}</>);
    if ((end = text.indexOf("]", start + 1)) > 0) {
      const substring = text.substring(start + 1, end);
      transformed.push(<span style={{
        color: brightPaletteColors[md5_code(substring) % brightPaletteColors.length],
        fontWeight: "bold"
      }}>{" " + substring}</span>);
    } else break;
  }
  transformed.push(<>{text.substring(end + 1)}</>);
  return <Typography {...props}>
    {transformed}
  </Typography>;
}

export function StyledEditableTypography(props: TypographyProps & {
  textRef?: Ref<HTMLParagraphElement>,
  boxStyle?: any,
  placeholder?: string,
  onTextInputConfirmed?: (event, text: string) => void,
  onContentEdited?: (event, text: string) => void,
}) {
  const [contentText, setContentText] = useState(props.children as string || "");
  // TODO: Figure out how to set div height to be equal to child placeholder or text element (dependeing on variant).
  return <StyledBoxRow style={{minHeight: "2rem", ...props.boxStyle}}>
    <StyledBoxRow style={{alignItems: "center", position: "relative", flexGrow: 1}}>
      {!props.children && contentText.length <= 0
        ? <Typography
          {...props}
          style={{
            pointerEvents: "none",
            color: mediumGray,
            position: "absolute",
          }}>{props.placeholder}</Typography>
        : null}
      <Typography
        {...props}
        ref={props.textRef}
        contentEditable
        onKeyDown={event => {
          if (event.keyCode === 13) {
            event.preventDefault();
            props.onTextInputConfirmed?.(event, contentText);
            event.currentTarget.blur();
          }
        }}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          verticalAlign: "middle",
          lineHeight: "2rem",
          ...props.style
        }}
        onBlur={event => props.onContentEdited?.(event, contentText)}
        onInput={event => setContentText(event.currentTarget.innerText)}
      >{props.children}</Typography>
    </StyledBoxRow>
  </StyledBoxRow>;
}

const STYLED_CONTAINER_SIZES = new Map<string, number>([
  ["xlg", PAGE_FRAGMENT_XLG_WIDTH],
  ["lg", PAGE_FRAGMENT_LG_WIDTH],
  ["md", PAGE_FRAGMENT_WIDTH],
  ["sm", PAGE_FRAGMENT_SM_WIDTH],
  ["xsm", PAGE_FRAGMENT_HALF_WIDTH],
]);

export function StyledContainer(props: BoxProps & { size?: "xlg" | "lg" | "md" | "sm" | "xsm" }) {
  return <StyledBoxColumn style={{
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexGrow: 1,
    maxWidth: STYLED_CONTAINER_SIZES.get(props.size) || PAGE_FRAGMENT_WIDTH,
    marginLeft: "auto",
    marginRight: "auto"
  }}>
    <StyledBoxColumn className={props.className} style={{padding: PD_SM, ...props.style}}>
      {props.children}
    </StyledBoxColumn>
  </StyledBoxColumn>;
}

export function StyledLink(props: { to: string, children: any }) {
  return <Typography><Link to={props.to}>
    {props.children}
  </Link></Typography>;
}

const MORE_BUTTON_SIZE_MAP = new Map<string, number>([
  ["lg", 72],
  ["md", 56],
  ["sm", 48],
]);


export function StyledButtonMore(props: { size?: "sm" | "md" | "lg", style?: any, actions: Action[] }) {
  const size = MORE_BUTTON_SIZE_MAP.get(props.size || "md");
  return <IconButton
    style={{width: size, height: size, ...props.style}}
    onMouseDown={event => {
      event.stopPropagation();
    }}
    onClick={event => {
      event.preventDefault();
      BaseApp.CONTEXT.showActions(event.currentTarget, null, props.actions);
    }}>
    <MoreVertRounded/>
  </IconButton>;
}

export function StyledButtonDebug(props: { size?: "sm" | "md" | "lg", style?: any, actions: Action[] }) {
  const size = MORE_BUTTON_SIZE_MAP.get(props.size || "md");
  return <IconButton
    style={{width: size, height: size, ...props.style}}
    onMouseDown={event => {
      event.stopPropagation();
    }}
    onClick={event => {
      event.preventDefault();
      BaseApp.CONTEXT.showActions(event.currentTarget, null, props.actions);
    }}>
    <BugReportOutlined/>
  </IconButton>;
}

export const StyledBadge = styled(Badge)(({theme}) => ({
  '& .MuiBadge-badge': {
    right: PD_SM,
    top: PD_SM,
    background: colorRed,
    color: white,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: PD_XSM,
  },
}));

type StyledUploadButtonProps = ButtonProps & {
  style?: any,
  mimeTypes: string[],
  uploadFile: (file: DesktopFile, parentId?: string) => Promise<any>,
}

type StyledUploadButtonState = {
  status: "none" | "uploading" | "done" | "error",
}

export class StyledUploadButton extends React.Component<StyledUploadButtonProps, StyledUploadButtonState> {

  private readonly inputSpec;

  constructor(props: StyledUploadButtonProps, context: any) {
    super(props, context);
    this.state = {
      status: "none",
    }
    this.inputSpec = {
      ref: React.createRef(),
      type: "file",
      multiple: false,
      accept: this.props.mimeTypes?.join(",") || "*",
      onChange: event => {
        event.preventDefault();
        let file = event.target.files[0];
        if (!file) {
          this.setState({
            status: "error",
          });
          return;
        }
        this.setState({
          status: "uploading",
        });
        this.props.uploadFile(new DesktopFile(new LocalFile(file, file.name, file.size)))
          .then((response) => {
            this.setState({
              status: "done",
            });
          })
          .catch(reason => {
            this.setState({
              status: "error",
            });
          })
        //this.listener.onPickImage(files[0]);
      }
    } as InputSpec;
  }

  render() {
    return <Box style={{
      display: "flex",
      minHeight: SZ_SM,
    }}>
      {this.renderContent()}
    </Box>;
  }

  private renderContent() {
    let style = this.props.style || {};
    switch (this.state.status) {
      case "none": {
        const input = createInput(this.inputSpec);
        return <Button
          {...this.props}
          onClick={() => this.inputSpec.ref.current.click()}
        >
          {input}
          Upload
        </Button>
      }
      case "uploading":
        return <Box style={{
          display: "flex",
          gap: PD_SM,
          alignItems: "center"
        }}>
          <CircularProgress/>
          <Typography>Uploading...</Typography>
        </Box>;
      case "done":
        return <Box style={{
          display: "flex",
          gap: PD_SM,
          alignItems: "center"
        }}>
          <Typography>Done</Typography>
        </Box>;
      case "error":
        return <Box style={{
          display: "flex",
          gap: PD_SM,
          alignItems: "center"
        }}>
          <Typography>Error</Typography>
        </Box>;
    }
  }
}

export function StyledGroupButton(props: { selected?: boolean } & ButtonProps) {
  return <Button
    style={{borderRadius: BORDER_RADIUS_LARGE, fontSize: "90%", paddingLeft: 16, paddingRight: 16, gap: PD_SM}}
    variant={props.selected ? "contained" : "outlined"}
    {...props}
  />
}

export type StyledListItemProps<T> = {
  disabled?: boolean,
  defaultImage?: string,
  path?: string,
  object?: T,
  variant?: "text" | "default" | "thumbnail",
  size?: "lg" | "md" | "sm",
  selected?: boolean,
  startDecorator?: ReactElement,
  endDecorator?: ReactElement,
  img?: string,
  icon?: typeof SvgIcon,
  iconForegroundColor?: string,
  iconBackgroundColor?: string,
  title: string,
  text?: string,
  titleSecondary?: string,
  textSecondary?: string,
  accessory?: ReactElement,
  noLink?: boolean,
  onClick?: (object: T) => void,
  style?: any,
}

const LIST_ITEM_THUMBNAIL_SIZE_MAP = new Map<string, number>([
  ["lg", SZ_JUMBO * 1.5],
  ["md", SZ_THUMBNAIL],
  ["sm", SZ_XXLG],
]);

export function StyledListItem<T>(props: StyledListItemProps<T>) {
  const theme = useTheme();
  switch (props.variant || "default") {
    case "default":
      return <ButtonBase
        disabled={props.disabled}
        to={props.path}
        onClick={() => props.onClick?.(props.object)}
        component={props.noLink || !props.path ? null : Link}>
        <Card style={{
          width: "100%",
          display: "flex",
          padding: PD_MD,
          background: props.selected ? colorHighlightAlt : null,
          ...props.style
        }}>
          <Box style={{
            width: "100%",
            height: SZ_SM,
            display: "flex",
            gap: PD_MD,
            textTransform: "none",
            alignItems: "center",
            ...props.style,
          }}>
            {props.icon ?
              <Box style={{
                width: SZ_SM,
                height: SZ_SM,
                flexShrink: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: props.iconBackgroundColor || theme.palette.primary.main,
                borderRadius: BORDER_RADIUS
              }}>
                <props.icon style={{color: props.iconForegroundColor || "white", fontSize: 28}}/>
              </Box>
              : ((props.img || props.defaultImage) ?
                <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
                  <img src={props.img || props.defaultImage}
                       style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                </Card>
                : props.startDecorator)
            }
            <Box style={{
              display: "flex", flexDirection: "column", flexGrow: 1, whiteSpace: "nowrap", overflow: "hidden",
            }}>
              <Box style={{display: "flex", alignItems: "center", gap: PD_XXSM}}>
                <Typography style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1,
                }}>{props.title}</Typography>
                {props.titleSecondary ?
                  <Typography style={{
                    flexGrow: 0,
                    flexShrink: 0,
                  }}>{props.titleSecondary}</Typography>
                  : null}
              </Box>
              <Box style={{display: "flex", width: 240, alignItems: "center", gap: PD_XXSM}}>
                <Typography style={{
                  textAlign: "start",
                  overflow: "hidden",
                  flexWrap: "nowrap",
                  textOverflow: "ellipsis"
                }}>{props.text}</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{alignSelf: "center"}}>
            {props.accessory}
          </Box>
        </Card>
      </ButtonBase>
    case "thumbnail":
      const size = LIST_ITEM_THUMBNAIL_SIZE_MAP.get(props.size || "md");
      return <Box style={{display: "flex", justifyContent: "center"}}>
        <Button
          color="secondary"
          variant="text"
          style={{
            borderRadius: BORDER_RADIUS_SMALL,
            width: size,
            height: size,
            display: "flex",
            flexDirection: "column",
            gap: PD_SM,
            textTransform: "none",
            alignItems: "center",
            position: "relative",
          }}
          onClick={() => props.onClick?.(props.object as T)}>
          <Card style={{
            position: "absolute",
            borderRadius: BORDER_RADIUS_SMALL,
            left: 0,
            top: 0,
            width: "100%",
            height: "100%"
          }}>
            <img src={props.img} style={{width: "100%", height: "100%", objectFit: "cover"}}/>
          </Card>
          <Box style={{position: "absolute", right: 0, top: 0}}>
            {props.accessory}
          </Box>
          <Box style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderColor: props.selected ? colorHighlightAlt : null,
            borderStyle: props.selected ? "solid" : null,
            borderWidth: props.selected ? 8 : 0,
            borderRadius: BORDER_RADIUS_SMALL,
          }}/>
        </Button>
      </Box>;
  }
  return null;
}

export function StyledEmpty(props: { emptyConfig: EmptyConfig, style?: any }) {
  let emptyConfig = props.emptyConfig;
  if (!emptyConfig) {
    return null;
  }
  let IconType = emptyConfig.iconType;
  let style = props.style || {};
  return <Box style={{
    display: "flex",
    flexDirection: "column",
    color: mediumGray,
    background: null,//lightGray,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: PD_XXLG,
    ...style,
  }}>
    <StyledBoxColumn style={{
      maxWidth: 400,
      alignItems: "center",
    }}>
      <IconType style={{fontSize: 72}}/>
      <Typography variant="h5" style={{marginTop: PD_SM, textAlign: "center"}}>{emptyConfig.title}</Typography>
      {Boolean(emptyConfig.text) ?
        <Typography style={{marginTop: PD_SM, textAlign: "center"}}>{emptyConfig.text}</Typography>
        : null}
      <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
        {Boolean(emptyConfig.action) ?
          <Button
            startIcon={emptyConfig.action.iconType ? <emptyConfig.action.iconType/> : null}
            variant="contained"
            style={{marginTop: PD_MD}}
            onClick={(event) => emptyConfig.action.onClick(event)}>
            {emptyConfig.action.text}
          </Button>
          : null}
        {Boolean(emptyConfig.altAction) ? <Button
            style={{marginTop: PD_MD}}
            onClick={(event) => emptyConfig.altAction.onClick(event)}>{emptyConfig.altAction.text}</Button>
          : null}
      </Box>
    </StyledBoxColumn>
  </Box>;
}

export function StyledUnselected(props: { unselectedConfig: EmptyConfig, style?: any }) {
  return <StyledEmpty emptyConfig={props.unselectedConfig} style={props.style}/>;
}


export class StyledVerticalStack extends React.PureComponent<{
  label?: string,
  tooltip?: string,
  gap?: string | boolean,
  gutterLabel?: boolean,
  style?: any,
  children?: any
}> {
  render() {
    let style = this.props.style || {};
    return <Box style={{
      ...style,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: PD_SM,
      marginTop: this.props.gap ? (typeof this.props.gap === "string" ? PD[this.props.gap] : (Boolean(this.props.gap) ? PD_SM : 0)) : 0,
    }}>
      {this.props.label
        ? <StyledBoxRow style={{alignItems: "center"}}>
          <Typography
            style={{
              display: "block",
              fontWeight: "bold",
              marginLeft: this.props.gutterLabel ? PD_SM : 0,
              marginBottom: PD_XXSM,
            }}>
            {this.props.label}
          </Typography>
          {this.props.tooltip
            ? <Tooltip title={this.props.tooltip}>
              <InfoOutlined style={{fontSize: "100%"}}/>
            </Tooltip>
            : null}
        </StyledBoxRow>
        : null}
      {this.props.children}
    </Box>;
  }
}

export class StyledHorizontalStack extends React.PureComponent<{
  label?: string,
  gap?: string | boolean,
  gutterLabel?: boolean,
  style?: any,
  children?: any
}> {
  render() {
    let style = this.props.style || {};
    return <Box style={{
      ...style,
      width: "100%",
      height: SZ_SM,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: PD_SM,
      marginTop: this.props.gap ? (typeof this.props.gap === "string" ? PD[this.props.gap] : (Boolean(this.props.gap) ? PD_SM : 0)) : 0,
    }}>
      {this.props.label ? <Box style={{
          display: "flex",
          width: 96,
          flexGrow: 0,
          flexShrink: 0,
          justifyContent: "flex-end",
        }}>
          <Typography style={{fontWeight: "bold",}}>
            {this.props.label}
          </Typography>
        </Box>
        : null}
      <Box style={{
        flexGrow: 1,
      }}>
        {this.props.children}
      </Box>
    </Box>;
  }
}

export class StyledToolBox extends React.PureComponent<{ children: any, title: string, actions?: Action[] }> {

  render() {
    return <Card style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      // borderRadius: BORDER_RADIUS,
      // border: DIVIDER
    }}>
      <Box style={{display: "flex", flexDirection: "row", padding: PD_XSM, marginBottom: -8, gap: PD_XXSM}}>
        <Typography style={{marginLeft: PD_XXSM, fontWeight: "bold"}}>{this.props.title}</Typography>
        <span style={{flexGrow: 1}}/>
        {this.props.actions?.map(action => {
          const IconType = action.iconType;
          return <Tooltip title={action.text} arrow>
            <IconButton onClick={(event) => action.onClick(event)}>
              <IconType style={{padding: PD_XXXSM}}/>
            </IconButton>
          </Tooltip>;
        })}
      </Box>
      {this.props.children}
    </Card>
  }
}

export class StyledToolstripTool {
  constructor(readonly name: string, readonly iconType: typeof SvgIcon, readonly hidden?: boolean) {
  }
}

type StyledToolstripProps = {
  orientation?: "horizontal" | "vertical",
  floating?: boolean,
  tools: StyledToolstripTool[],
  initialSelectedToolIndex: number,
  onSelectedToolChanged: (tool) => void,
}

type StyledToolstripState = {
  selectedTool: string,
}

export class StyledToolstrip extends React.PureComponent<StyledToolstripProps, StyledToolstripState> {

  constructor(props: StyledToolstripProps, context: any) {
    super(props, context);
    this.state = {
      selectedTool: this.props.tools[this.props.initialSelectedToolIndex].name,
    };
  }

  componentDidMount() {
    this.props.onSelectedToolChanged(this.state.selectedTool);
  }

  render() {
    return this.props.orientation === "vertical" ? this.renderVertical() : this.renderHorizontal();
  }

  private renderHorizontal() {
    if (this.props.floating) {
      return <Box style={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        alignItems: "flex-start",
        justifyContent: "center",
        zIndex: 1200,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}>
        {this.renderHorizontalInternal()}
      </Box>;
    } else {
      return this.renderHorizontalInternal();
    }
  }

  private renderHorizontalInternal() {
    return <ToggleButtonGroup
      value={this.state.selectedTool}
      exclusive
      onChange={(e, value) => {
        this.setState({
          selectedTool: value,
        });
        this.props.onSelectedToolChanged(value);
      }}
      style={{margin: PD_SM, height: SZ_SM}}
    >
      {this.props.tools.filter(tool => !tool.hidden).map(tool => this.createToggleButton(tool.name, tool.iconType))}
    </ToggleButtonGroup>;
  }

  private renderVertical() {
    if (this.props.floating) {
      return <Box style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "flex-start",
        justifyContent: "center",
        pointerEvents: "none",
        zIndex: 1200,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}>
        {this.renderVerticalInternal()}
      </Box>;
    } else {
      return this.renderVerticalInternal();
    }
  }

  private renderVerticalInternal() {
    return <ToggleButtonGroup
      orientation="vertical"
      value={this.state.selectedTool}
      exclusive
      onChange={(e, value) => {
        this.setState({
          selectedTool: value,
        });
        this.props.onSelectedToolChanged(value);
      }}
      style={{margin: PD_SM, width: SZ_SM, pointerEvents: "auto"}}
    >
      {this.props.tools.filter(tool => !tool.hidden).map(tool => this.createToggleButton(tool.name, tool.iconType))}
    </ToggleButtonGroup>;
  }

  private createToggleButton(value: string, iconType: typeof SvgIcon): ReactElement {
    const theme = BaseApp.CONTEXT.getAppConfig().theme;
    let IconType = iconType;
    return <ToggleButton
      style={{
        backgroundColor: this.state.selectedTool === value ? theme.palette.primary.main : white,
        width: 48,
        height: 48
      }}
      value={value}>
      <IconType style={this.state.selectedTool === value ? ICON_BUTTON_LIGHT_XSM_STYLE : ICON_BUTTON_XSM_STYLE_PD}/>
    </ToggleButton>;
  }
}

export type StyledDashboardWidgetRenderer = {
  title?: string,
  rows: number,
  columns: number,
  render: (width: number, height: number) => ReactElement,
}

export type StyledDashboardWidgetProps = {
  cellSize: number,
  renderer: StyledDashboardWidgetRenderer,
}

type StyledDashboardWidgetState = {}

export class StyledDashboardWidget extends React.Component<StyledDashboardWidgetProps, StyledDashboardWidgetState> {

  render(): ReactElement {
    const width = this.props.cellSize * this.props.renderer.columns;
    const height = this.props.cellSize * this.props.renderer.rows;
    return <Box style={{
      display: "flex",
      flexDirection: "column",
      margin: PD_MD,
      padding: PD_SM,
      borderRadius: BORDER_RADIUS,
      border: DIVIDER,
      background: white
    }}>
      {this.props.renderer.title ?
        <Typography style={{fontWeight: "bold", padding: PD_SM}}>{this.props.renderer.title}</Typography>
        : null}
      <Box style={{width: width, height: height}}>
        {this.props.renderer.render(width, height)}
      </Box>
    </Box>;
  }
}

export type StyledDashboardWidgetContainerProps = {
  renderers: StyledDashboardWidgetRenderer[],
}

export class StyledDashboardWidgetContainer extends React.PureComponent<StyledDashboardWidgetContainerProps> {

  render(): ReactElement {
    return <StyledBoxRow style={{flexGrow: 1, alignItems: "flex-start"}}>
      {this.props.renderers.map(renderer => <StyledDashboardWidget cellSize={72} renderer={renderer}/>)}
    </StyledBoxRow>
  }
}

export function StyledMarkdown(props: { style?: any, children: any, options?: Options }) {
  return <Typography component="div" style={{fontSize: "100%", ...props.style}}>
    <Markdown
      components={{
        img: (imageData) => {
          // const size = imageData.alt?.match(/\{(\d+)x(\d+)}/);
          // const width = size ? size[1] : null;
          // const height = size ? size[2] : null;
          return <img src={imageData.src} style={{maxWidth: 320, maxHeight: 320, objectFit: "contain"}}/>
        },
      }}
      {...props.options}>
      {props.children}
    </Markdown>
  </Typography>;
}