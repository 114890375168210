import React, {ReactElement, useState} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledMarkdown, StyledSpan} from "./StyledComponents";
import {Box, Button, TextareaAutosize, Typography} from "@mui/material";
import {BORDER_RADIUS, PAGE_FRAGMENT_HALF_WIDTH, PD_SM, SZ_LG} from "./dimens";
import {FormGenContentItem, FormGenLayoutSource} from "./FormGenContainer";
import Markdown from "react-markdown";
import {gray, lightGray, mediumGray} from "./colors";

export function FormGenRichText(props: {
  placeholder?: string,
  disabled?: boolean,
  contentHeight: number,
  contentItem: FormGenContentItem,
  index: number,
  source: FormGenLayoutSource
}): ReactElement {
  const [preview, setPreview] = useState<boolean>(false);
  const source = props.source;
  const contentItem = props.contentItem;
  return <StyledBoxColumn>
    <StyledBoxRow style={{marginTop: -32}}>
      <StyledSpan/>
      <Button variant={preview ? "contained" : "text"} onClick={() => setPreview(!preview)}>Preview</Button>
    </StyledBoxRow>
    {preview
      ? <StyledBoxColumn
        style={{
          boxSizing: "border-box",
          border: mediumGray + " 1px solid",
          borderRadius: BORDER_RADIUS,
          height: props.contentHeight,
          padding: PD_SM,
          overflowY: "scroll"
        }}>
        <StyledMarkdown>
          {source.getValue(contentItem)}
        </StyledMarkdown>
      </StyledBoxColumn>
      : <TextareaAutosize
        placeholder={props.placeholder}
        disabled={props.disabled}
        style={{
          boxSizing: "border-box",
          minWidth: PAGE_FRAGMENT_HALF_WIDTH,
          height: props.contentHeight,
          flexGrow: 1,
          resize: "vertical",
          overflow: "auto",
          fontFamily: "sans-serif",
          fontSize: "100%",
          padding: PD_SM
        }}
        value={source.getValue(contentItem) || ""}
        onChange={event => source.setValue(contentItem, event.target.value)}
        id={contentItem.id}
        required/>}
  </StyledBoxColumn>;
}