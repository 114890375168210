// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyD4PW-EkfMYF5t-Uq7vft3jreIGksPzCOs",
//   authDomain: "plugins-playground-7971a.firebaseapp.com",
//   projectId: "plugins-playground-7971a",
//   storageBucket: "plugins-playground-7971a.appspot.com",
//   messagingSenderId: "66818322724",
//   appId: "1:66818322724:web:c03ec9054d26ffb40e83b1",
//   measurementId: "G-H0VZ9TTFMY"
// };

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBgaf9mr2MqMPxvFCBdltIPb_aRxvJHCUA",
  authDomain: "klip5-apps.firebaseapp.com",
  databaseURL: "https://klip5-apps-default-rtdb.firebaseio.com",
  projectId: "klip5-apps",
  storageBucket: "klip5-apps.appspot.com",
  messagingSenderId: "442081400701",
  appId: "1:442081400701:web:268fa57aa7fd37f4f69e14",
  measurementId: "G-00X9E74CCX",
};