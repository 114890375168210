// export const PD_XXXSM = 2;
// export const PD_XXSM = 4;
// export const PD_XSM = 8;
// export const PD_SM = 12;
// export const PD_MD = 16;
// export const PD_LG = 24;
// export const PD_XLG = 32;
// export const PD_XXLG = 72;
// export const PD_XXXLG = 96;
// export const PD_JUMBO = 120;
export const PD_XXXSM = 1;
export const PD_XXSM = 2;
export const PD_XSM = 4;
export const PD_SM = 8;
export const PD_MD = 12;
export const PD_LG = 16;
export const PD_XLG = 24;
export const PD_XXLG = 72;
export const PD_XXXLG = 96;
export const PD_JUMBO = 120;
export const PD = {
  'xxsm': PD_XXSM,
  'xsm': PD_XSM,
  'sm': PD_SM,
  'md': PD_MD,
  'lg': PD_LG,
  'xlg': PD_XLG,
  'xxlg': PD_XXLG,
  'xxxlg': PD_XXXLG,
  'jumbo': PD_JUMBO,
}

export const TAB_SIZE = 72;
export const BORDER_RADIUS = 4;//24;
export const BORDER_RADIUS_SMALL = 2;
export const BORDER_RADIUS_LARGE = 24;
// export const CARD_SHADOW = '0px 2px 32px 0px rgba(0, 0, 0, .1)';
export const CARD_SHADOW = '0px 0px 0px 1px rgba(0, 0, 0, .05)';
// export const CARD_SHADOW = '1px 1px 0px 0px rgba(0, 0, 0, .1)';

export const DIVIDER_COLOR = 'rgba(0, 0, 0, 0.1)';
export const DIVIDER = '1px solid ' + DIVIDER_COLOR;
export const DIVIDER_BLACK = '2px solid black';

export const CARD_HORIZONTAL_PADDING = PD_SM;
export const CARD_VERTICAL_PADDING = PD_SM;
export const SIDEBAR_WIDTH = 360;
export const SIDEBAR_WIDTH_SM = 280;
export const LEFT_SIDEBAR_WIDTH = 360;
export const RIGHT_SIDEBAR_WIDTH = 440;

export const SZ_XXSM = 16;
export const SZ_XSM = 24;
export const SZ_SSM = 32;
export const SZ_SMSM = 40;
export const SZ_SM = 48;
export const SZ_SMD = 56;
export const SZ_MD = 72;
export const SZ_LG = 96;
export const SZ_XLG = 120;
export const SZ_XXLG = 160;
export const SZ_XXXLG = 192;
export const SZ_HEADER = 160;
export const SZ_ACTION_PANEL = 80;
export const SZ_JUMBO = 240;
export const SZ_THUMBNAIL = 240;
export const SZ_CHAT_BAR = 72;
// export const CARD_SHADOW = '0px 24px 48px -16px rgba(0, 0, 0, .25)';
// export const CARD_SHADOW = '0px 24px 48px -16px rgba(0, 0, 0, .1)';

export const DW_XS = 320;
export const DW_SM = 480;
export const DW_MD = 648;
export const DW_LG = 720;
export const DW_XL = 960;
export const DW_XXL = 1200;
export const DW_JUMBO = '90vw';
export const DW = {
  'xs': DW_XS,
  'sm': DW_SM,
  'md': DW_MD,
  'lg': DW_LG,
  'xl': DW_XL,
  'xxl': DW_XXL,
  'jumbo': DW_JUMBO,
}

export const PAGE_FRAGMENT_XXLG_WIDTH = 1200;
export const PAGE_FRAGMENT_XLG_WIDTH = 1080;
export const PAGE_FRAGMENT_LG_WIDTH = 960;
export const PAGE_FRAGMENT_WIDTH = 720;
export const PAGE_FRAGMENT_SM_WIDTH = 480;
export const PAGE_FRAGMENT_HALF_WIDTH = 360;
