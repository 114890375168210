import React, {createRef, ReactElement} from "react";
import {PathComponent} from "index";
import {StyledBoxColumn, StyledBoxRow, StyledEditableTypography, StyledSpan} from "../shared/StyledComponents";
import {DIVIDER_BLACK, PD_MD, PD_SM, SZ_JUMBO, SZ_MD, SZ_SM} from "../shared/dimens";
import {Button, ButtonBase, Card, Grid, Typography} from "@mui/material";
import {Action, ActionActionsListPopover, UserProfilePhoto} from "../shared/types";
import {UserFragment} from "../shared/UserFragment";
import {App, COLOR_PRIMARY, COLOR_SECONDARY} from "./App";
import {getMemberAuth} from "../shared/auth";
import {PageFragment, PageFragmentProps, PageFragmentState} from "../shared/PageFragment";
import {ArrowBackIosOutlined, EditOutlined, HomeOutlined, PlayArrowOutlined, StopOutlined} from "@mui/icons-material";
import {Editor} from "./components/Editor";
import {gray} from "../shared/colors";
import {
  BaseApp,
  DIALOG_FLAG_DISABLE_BACKDROP_CLICK,
  DIALOG_FLAG_SHOW_CLOSE,
  showKeyboardShortcutsDialog
} from "../shared/BaseApp";
import {Save} from "./components/Save";
import {Klip} from "./types";
import {Home} from "./components/Home";
import {AboutFragment} from "../shared/AboutFragment";

export type MainProps = PageFragmentProps & {}

export enum MainMode {
  HOME = "home",
  EDIT = "edit",
  SAVE = "save",
}

type MainState = PageFragmentState & {
  klip: Klip,
  url?: string,
  mode: MainMode,
}

const HTML = `
<!DOCTYPE html>
<html lang="en">
  <head>
  <style>
    html, body {
      margin: 0;
      padding: 0;
    }
    canvas {
      display: block;
    }
  </style>
    <script src="___HOST___/p5/p5.js"></script>
    <meta charset="utf-8">
  </head>
  <body>
    <script>
    ___SCRIPT___
   </script>
  </body>
</html>
`;

export class Main extends PageFragment<MainProps, MainState> {

  private readonly memberAuth = getMemberAuth();
  private readonly editorRef = createRef<Editor>();
  private readonly previewRef = createRef<HTMLIFrameElement>();

  constructor(props: MainProps, context: any) {
    super(props, context);
    this.state = {
      klip: Klip.createNew(),
      mode: MainMode.EDIT,
    };
  }

  static nestedPaths(): PathComponent[] {
    return [];
  }

  private renderMenubar(): ReactElement {
    return <StyledBoxRow style={{
      paddingLeft: PD_SM,
      paddingRight: PD_SM,
      borderBottom: DIVIDER_BLACK,
      gap: PD_SM,
      alignItems: "center",
      backgroundColor: COLOR_PRIMARY,
      height: SZ_MD,
      flexShrink: 0,
    }}>
      <img src="/icon.png" style={{width: 64, aspectRatio: 1}}/>
      <Button variant="contained" style={{minWidth: 0, width: 40, height: 40}}
              onClick={() => this.setState({mode: MainMode.HOME})}>
        <HomeOutlined/>
      </Button>
      {this.state.mode === MainMode.HOME
        ? <>
          {this.renderToolbarButtonEx(new Action(this.state.klip.metadata.title, () => this.setState({mode: MainMode.EDIT}), EditOutlined).setVariant("contained"))}
        </>
        : <>
          {this.renderToolbarButtonEx(new ActionActionsListPopover([
            new Action("New", () => this.onClipNew()).setKeyboardShortcut("Cmd + N"),
            new Action("Save", () => this.onClipSave()).setKeyboardShortcut("Cmd + S"),
          ], "File").setVariant("contained"))}
          {/*{this.renderToolbarButtonEx(new ActionActionsListPopover([*/}
          {/*  new Action("Tidy code"),*/}
          {/*  new Action("Find"),*/}
          {/*  new Action("Replace"),*/}
          {/*], "Edit").setVariant("contained"))}*/}
          {this.renderToolbarButtonEx(new ActionActionsListPopover([
            new Action("Run", () => this.onClipRun()),
            new Action("Stop", () => this.onClipStop()),
          ], "Klip").setVariant("contained"))}
          {this.renderToolbarButtonEx(new ActionActionsListPopover([
            new Action("Keyboard shortcuts", () => showKeyboardShortcutsDialog(BaseApp.CONTEXT.getAppConfig().keyboardConfig?.shortcuts)),
            // new Action("Reference"),
            new Action("About", () => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>
              <AboutFragment/>)),
          ], "Help").setVariant("contained"))}
        </>}
      <StyledSpan/>
      <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
        <ButtonBase
          onClick={() => App.CONTEXT.showDialog(null, () => <UserFragment uid={this.memberAuth.member.uid}/>)}>
          <img src={UserProfilePhoto(this.memberAuth.member.user)} style={{width: "100%", height: "100%"}}/>
        </ButtonBase>
      </Card>
    </StyledBoxRow>
  }

  private renderClipToolbar(): ReactElement {
    return <StyledBoxRow
      style={{
        borderBottom: DIVIDER_BLACK, gap: PD_MD, alignItems: "center", padding: PD_SM, height: SZ_MD, flexShrink: 0,
      }}>
      {this.state.mode === MainMode.HOME
        ? <>
          <Typography variant="h5">Home</Typography>
        </>
        : null}
      {this.state.mode === MainMode.SAVE
        ? <>
          <Button startIcon={<ArrowBackIosOutlined/>} variant="contained"
                  onClick={() => this.setState({mode: MainMode.EDIT})}>Back</Button>
          <Typography variant="h5">{this.state.klip.metadata.title}</Typography>
        </>
        : null}
      {this.state.mode === MainMode.EDIT
        ? <>
          <Button color="secondary"
                  variant="contained"
                  style={{
                    background: this.state.url ? COLOR_SECONDARY : gray,
                    width: 48,
                    height: 48,
                    borderRadius: 24
                  }}
                  onClick={() => this.onClipRun()}>
            <PlayArrowOutlined style={{width: 36, height: 36}}/>
          </Button>
          <Button
            color="secondary"
            variant="contained" style={{
            background: !this.state.url ? COLOR_SECONDARY : gray,
            width: 48,
            height: 48,
            borderRadius: 24
          }}
            onClick={() => this.onClipStop()}>
            <StopOutlined style={{width: 36, height: 36}}/>
          </Button>
          <div style={{minWidth: SZ_JUMBO}}>
            <StyledEditableTypography variant="h5" style={{minWidth: SZ_JUMBO, flexGrow: 1}}
                                      onContentEdited={(event, text) => this.state.klip.metadata.title = text}>
              {this.state.klip.metadata.title}
            </StyledEditableTypography>
          </div>
        </>
        : null}
      <StyledSpan/>
      {this.state.mode === MainMode.EDIT
        ? <Button variant="contained" onClick={() => this.onClipSave()}>Save</Button>
        : null}
    </StyledBoxRow>
  }

  private onClipNew() {
    BaseApp.CONTEXT.showTextDialog(
      "Confirm",
      "All unsaved changes from the current klip will be lost.",
      new Action("Proceed", () => this.setState({
        klip: Klip.createNew(),
        url: null
      })));
  }

  private onClipSave() {
    this.setState({mode: MainMode.SAVE});
  }

  private onClipRun() {
    const value = this.editorRef.current.getValue();
    const html = HTML.replace("___HOST___", window.location.protocol + "//" + window.location.host).replace("___SCRIPT___", value);
    const blob = new Blob([html], {type: 'text/html'});
    const url = window.URL.createObjectURL(blob);
    this.setState({url: url});
  }

  private onClipStop() {
    this.setState({url: null});
  }

  private renderModeHome() {
    return <Home klip={this.state.klip} setMode={mode => this.setState({mode: mode})}
                 loadKlip={klip => this.setState({klip: klip, url: null})}/>;
  }

  private renderModeEdit() {
    return <Grid container style={{flexGrow: 1}}>
      <Grid item xs={12} sm={6} style={{position: "relative"}}>
        <Editor ref={this.editorRef} klip={this.state.klip}/>
      </Grid>
      <Grid item xs={12} sm={6} style={{borderLeft: DIVIDER_BLACK}}>
        {this.state.url
          ? <iframe ref={this.previewRef} width="100%" height="100%" src={this.state.url} frameBorder={0}/>
          : null}
      </Grid>
    </Grid>;
  }

  private renderModeSave() {
    return <Save klip={this.state.klip} draftText={Editor.draftText || ""}
                 setMode={mode => this.setState({mode: mode})}/>;
  }

  private renderMode() {
    switch (this.state.mode) {
      default:
      case MainMode.HOME:
        return this.renderModeHome();
      case MainMode.EDIT:
        return this.renderModeEdit();
      case MainMode.SAVE:
        return this.renderModeSave();
    }
    return null;
  }

  renderContent(): React.ReactElement {
    return <StyledBoxColumn style={{width: "100vw", height: "100vh", gap: 0}}>
      {this.renderMenubar()}
      {this.renderClipToolbar()}
      {this.renderMode()}
    </StyledBoxColumn>;
  }
}
