import React from "react";
import {Button, Grid} from "@mui/material";
import {StyledContainer, StyledEmpty} from "shared/StyledComponents";
import {DIVIDER, DIVIDER_BLACK} from "../../shared/dimens";
import {AutoAwesomeOutlined, FileOpenOutlined} from "@mui/icons-material";
import {Action, KeyTextStrings} from "shared/types";
import {$KTS} from "../../shared/keytext";
import {Klip, Klips} from "../types";
import {MainMode} from "../Main";
import {createShowGridItemForKlip, ShowGrid} from "./ShowGrid";
import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";


enum Tab {
  KLIPS = "klips",
  ASSETS = "assets",
}

const TAB_KTS = new KeyTextStrings([
  $KTS(Tab.KLIPS, "Klips"),
  $KTS(Tab.ASSETS, "Assets"),
]);

function renderKlipsTab(setMode: (mode: MainMode) => void, loadKlip: (klip: Klip) => void) {
  const klips = Klips.getInstance().getListItems();
  return klips.length > 0
    ? <ShowGrid items={klips.map(klip => createShowGridItemForKlip(klip, () => {
      loadKlip(klip);
      setMode(MainMode.EDIT);
    }))} minCount={6}/>
    : <StyledEmpty emptyConfig={{
      title: "You haven't created any clips yet.",
      iconType: AutoAwesomeOutlined,
      action: new Action("Continue editing →", () => setMode(MainMode.EDIT)),
      altAction: new Action("Create new klip", () => {
      }),
    }}/>;
}

function renderAssetsTab() {
  return <StyledEmpty emptyConfig={{
    title: "You haven't uploaded any assets yet.",
    iconType: FileOpenOutlined,
    // action: new Action("Upload"),
  }}/>;
}

function renderTab(tab: Tab, setMode: (mode: MainMode) => void, loadKlip: (klip: Klip) => void) {
  switch (tab) {
    case Tab.KLIPS:
      return renderKlipsTab(setMode, loadKlip);
    case Tab.ASSETS:
      return renderAssetsTab();
  }
  return null;
}

export type HomeProps = PageFragmentProps & {
  klip: Klip,
  setMode: (mode: MainMode) => void,
  loadKlip: (klip: Klip) => void,
}

type HomeState = PageFragmentState & {
  selectedTab: Tab,
}

export class Home extends PageFragment<HomeProps, HomeState> {

  private readonly klips = Klips.getInstance();

  protected onCreateState(): HomeState {
    return {
      ...super.onCreateState(),
      selectedTab: Tab.KLIPS,
    };
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await this.klips.getOrLoadListItems();
  }

  renderContent(): React.ReactElement {
    return <StyledContainer size="xlg">
      <Grid container>
        {TAB_KTS.values.map(value => <Grid item xs={6}>
          <Button style={{width: "100%", borderBottom: this.state.selectedTab === value.key ? DIVIDER_BLACK : DIVIDER}}
                  onClick={() => this.setState({selectedTab: value.key as Tab})}>
            {value.text}
          </Button>
        </Grid>)}
      </Grid>
      {renderTab(this.state.selectedTab, this.props.setMode, this.props.loadKlip)}
    </StyledContainer>;
  }
}