import {AppConfig, BaseApp, BaseAppProps, BaseAppState} from "shared/BaseApp";
import React, {ReactElement} from 'react';
import {createTheme, ThemeOptions} from "@mui/material";
import {DEFAULT_THEME_OPTIONS} from "../shared/colors";
import {Main} from "./Main";
import {PathComponent} from "../index";
import {FIREBASE_CONFIG} from "../consts";
import {BORDER_RADIUS, PD_LG, PD_XSM, SZ_MD} from "../shared/dimens";
import {Auth, indexedDBLocalPersistence, initializeAuth} from "@firebase/auth";
import {FirebaseApp} from "firebase/app";
import {Capacitor} from "@capacitor/core";

export type AppProps = BaseAppProps & {}

type AppState = BaseAppState & {}

export const COLOR_PRIMARY = "#6633ff";
export const COLOR_SECONDARY = "#ffff00";

export const THEME_OPTIONS: ThemeOptions = {
  ...DEFAULT_THEME_OPTIONS,
  components: {
    ...DEFAULT_THEME_OPTIONS.components,
    MuiButton: {
      ...DEFAULT_THEME_OPTIONS.components.MuiButton,
      styleOverrides: {
        root: {
          fontWeight: "600",
          borderRadius: 0,
          fontSize: "120%",
          paddingTop: PD_XSM,
          paddingBottom: PD_XSM,
          paddingLeft: PD_LG,
          paddingRight: PD_LG,
          textTransform: "none",
        },
      }
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  typography: {
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
    fontFamily: 'Poppins, sans-serif',
    fontSize: 13,
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY,
      contrastText: "#fff",
    },
    secondary: {
      main: COLOR_SECONDARY,
      contrastText: "#000",
    },
    // background: {
    //   default: colorAlabaster,
    // },
  }
};

export const THEME = createTheme(THEME_OPTIONS);

export class App extends BaseApp<AppProps, AppState> {

  protected getAuth(app: FirebaseApp): Auth {
    return Capacitor.isNativePlatform()
      ? initializeAuth(app, {
        persistence: indexedDBLocalPersistence
      })
      : super.getAuth(app);
  }

  onCreateAppConfig(): AppConfig {
    return {
      name: "Klip5 Editor",
      icon: "/icon.png",
      logo: "/logo192.png",
      stamp: "/stamp.png",
      stampHeight: SZ_MD,
      defaultUserImage: "/images/default_user.png",
      safeAreaInsets: {
        bottom: Capacitor.getPlatform() === "ios" ? 12 : 0,
      },
      theme: THEME,
      firebaseConfig: {options: FIREBASE_CONFIG},
      keyboardConfig: {
        shortcuts: {
          sections: [
            {
              title: "Controls",
              shortcuts: [
                {
                  text: "Play",
                  kbd: ["Cmd", "Return"],
                },
                {
                  text: "Code",
                  kbd: ["Cmd", "Shift", "Return"],
                },
              ]
            }
          ]
        }
      },
    };
  }

  static nestedPaths(): PathComponent[] {
    return Main.nestedPaths();
  }

  protected renderMain(): ReactElement {
    return <>
      <Main path={this.props.path}/>
    </>;
  }
}
