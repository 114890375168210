import {Component, createRef} from "react";
import CodeMirror from "codemirror";
import emmet from "@emmetio/codemirror-plugin";
import {JSHINT} from "jshint";
import {CSSLint} from "csslint";
import {HTMLHint} from "htmlhint";
import classNames from "classnames";
import "codemirror/mode/css/css";
import "codemirror/mode/clike/clike";
import "codemirror/addon/selection/active-line";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/javascript-lint";
import "codemirror/addon/lint/css-lint";
import "codemirror/addon/lint/html-lint";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/indent-fold";
import "codemirror/addon/fold/xml-fold";
import "codemirror/addon/comment/comment";
import "codemirror/keymap/sublime";
import "codemirror/addon/search/searchcursor";
import "codemirror/addon/search/matchesonscrollbar";
import "codemirror/addon/search/match-highlighter";
import "codemirror/addon/search/jump-to-line";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/selection/mark-selection";
import "codemirror/addon/hint/css-hint";
import {Klip} from "../types";

emmet(CodeMirror);

//@ts-ignore
window.JSHINT = JSHINT;
//@ts-ignore
window.CSSLint = CSSLint;
//@ts-ignore
window.HTMLHint = HTMLHint;

const INDENTATION_AMOUNT = 2;

export type EditorProps = {
  klip: Klip,
  autocloseBracketsQuotes?: boolean,
  autocompleteHinter?: boolean,
  lineNumbers?: boolean,
  lintWarning?: boolean,
  linewrap?: boolean,
  lintMessages?: [{
    severity: "error" | "hint" | "info" | "warning",
    line: number,
    message: string,
    id: number
  }],
  consoleEvents?: [{
    method: string,
    args: string[],
  }],
  updateLintMessage?: () => void,
  clearLintMessage?: () => void,
  updateFileContent?: () => void,
  fontSize?: number,
  file?: {
    name: string,
    content: string,
    id: string,
    fileType: string,
    url?: string,
  },
  setUnsavedChanges?: () => void,
  startSketch?: () => void,
  autorefresh?: boolean,
  isPlaying?: boolean,
  theme?: string,
  unsavedChanges?: boolean,
  files?: [{
    id: string,
    name: string,
    content: string
  }],
  isExpanded?: boolean,
  collapseSidebar?: () => void,
  closeProjectOptions?: () => void,
  expandSidebar?: () => void,
  clearConsole?: () => void,
  hideRuntimeErrorWarning?: () => void,
  runtimeErrorWarningVisible?: boolean,
  provideController?: () => void,
  setSelectedFile?: () => void,
  expandConsole?: () => void
}

type EditorState = {}

export class Editor extends Component<EditorProps, EditorState> {

  static draftText: string = "";

  private codemirrorContainer = createRef<HTMLElement>();
  private _cm;

  constructor(props: EditorProps, context: any) {
    super(props, context);
    Editor.draftText = this.props.klip?.scriptText || Editor.draftText;
    this.state = {};
  }

  componentDidUpdate(prevProps: Readonly<EditorProps>, prevState: Readonly<EditorState>, snapshot?: any) {
    if (prevProps.klip?.id !== this.props.klip?.id) {
      Editor.draftText = this.props.klip?.scriptText || "";
      this._cm.setValue(Editor.draftText);
    }
  }

  getValue(): string {
    return this._cm.getValue();
  }

  render() {
    const editorHolderClass = classNames({
      'editor-holder': true,
      'editor-holder--hidden':
        this.props.file?.fileType === 'folder' || this.props.file?.url
    });
    return <article
      ref={this.codemirrorContainer}
      className={editorHolderClass}
    />;
  }

  componentDidMount() {
    console.log("mount");
    if (!this._cm) {
      this._cm = CodeMirror(this.codemirrorContainer.current, {
        theme: `p5-light`,//${this.props.theme}`,
        lineNumbers: true,//this.props.lineNumbers,
        styleActiveLine: true,
        inputStyle: 'contenteditable',
        lineWrapping: true,//this.props.linewrap,
        fixedGutter: false,
        foldGutter: true,
        foldOptions: {widget: '\u2026'},
        gutters: ['CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
        keyMap: 'sublime',
        highlightSelectionMatches: true, // highlight current search match
        matchBrackets: true,
        emmet: {
          preview: ['html'],
          markTagPairs: true,
          autoRenameTags: true
        },
        autoCloseBrackets: true,// this.props.autocloseBracketsQuotes,
        styleSelectedText: true,
        // lint: {
        //   onUpdateLinting: (annotations) => {
        //     // this.updateLintingMessageAccessibility(annotations);
        //   },
        //   options: {
        //     asi: true,
        //     eqeqeq: false,
        //     '-W041': false,
        //     esversion: 11
        //   }
        // },
        colorpicker: {
          type: 'sketch',
          mode: 'edit'
        }
      });
      this._cm.on("blur", () => Editor.draftText = this._cm.getValue());
      this._cm.setValue(Editor.draftText);
      console.log("set val: "+ Editor.draftText);
    }
  }
}