import {Button, ButtonBase, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {UserDisplayName} from "../../shared/types";
import {brightPaletteColors} from "../../shared/colors";
import {useState} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "shared/StyledComponents";
import {DIVIDER_BLACK, PD_MD, SZ_XXLG} from "../../shared/dimens";
import {Klip} from "../types";

type Pattern = {
  imageUrl: string,
  opacity: number,
}

const PATTERNS: Pattern[] = [
  {
    imageUrl: "/images/pattern1.png",
    opacity: 0.25,
  },
  {
    imageUrl: "/images/pattern2.png",
    opacity: 0.1,
  },
  {
    imageUrl: "/images/pattern3.png",
    opacity: 0.1,
  },
];

export type ShowGridItem = {
  imageUrl: string,
  onClick: () => void,
  title: string,
  text: string,
}

export function createShowGridItemForKlip(klip: Klip, onClick: () => void): ShowGridItem {
  return {
    title: klip.metadata.title,
    imageUrl: klip.coverImageUrl,
    onClick: onClick,
    text: "by " + UserDisplayName(klip.member.user),
  };
}

type ShowGridItem_ = ShowGridItem & {
  pattern: Pattern,
  color: string,
}

function createShowGridItem(item: ShowGridItem): ShowGridItem_ {
  return ({
    ...item,
    pattern: PATTERNS[Math.floor(PATTERNS.length * Math.random())],
    color: brightPaletteColors[Math.floor(brightPaletteColors.length * Math.random())]
  });
}

export function ShowGrid(props: { items?: ShowGridItem[], minCount?: number }) {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  let minCount = props.minCount || 0;
  if (md) {
    minCount += (3 - minCount % 3) % 3;
  } else if (sm) {
    minCount += minCount % 2;
  }
  const items: ShowGridItem_[] = props.items?.map(item => createShowGridItem(item)) || [];
  while (items.length < minCount) {
    // items.splice(Math.floor(Math.random() * items.length) + 1, 0, createShowGridItem(null));
    items.push(createShowGridItem(null));
  }
  return <StyledBoxColumn>
    <div style={{padding: 2, background: "black"}}>
      <Grid container spacing={0.25}>
        {items.slice(pageIndex * props.minCount, (pageIndex + 1) * props.minCount).map(item => <Grid item xs={12} sm={6}
                                                                                                     md={4}>
          <ButtonBase onClick={() => item.onClick()} style={{textAlign: "start"}}>
            <StyledBoxColumn style={{width: "100%", background: item.color, gap: 0}}>
              <div style={{position: "relative", borderBottom: DIVIDER_BLACK}}>
                <img src={item.pattern.imageUrl}
                     style={{
                       visibility: item.imageUrl ? "hidden" : "visible",
                       display: "block",
                       width: "100%",
                       aspectRatio: 1,
                       objectFit: "cover",
                       opacity: item.pattern.opacity,
                     }}/>
                {item.imageUrl
                  ? <img src={item.imageUrl} style={{
                    display: "block",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    aspectRatio: 1,
                    objectFit: "cover",
                  }}/>
                  : null}
              </div>
              <StyledBoxColumn style={{padding: PD_MD, gap: 0}}>
                <Typography variant="h6">{item.title || "Coming soon"}</Typography>
                <Typography>{item.text || "-"}</Typography>
              </StyledBoxColumn>
            </StyledBoxColumn>
          </ButtonBase>
        </Grid>)}
      </Grid>
    </div>
    <StyledBoxRow>
      <StyledSpan/>
      <Button
        disabled={pageIndex <= 0}
        variant="outlined"
        style={{width: SZ_XXLG}}
        onClick={() => setPageIndex(pageIndex - 1)}>Previous</Button>
      <Button
        disabled={pageIndex >= Math.floor(((items.length || 1) - 1) / minCount)}
        variant="outlined"
        style={{width: SZ_XXLG}}
        onClick={() => setPageIndex(pageIndex + 1)}>Next</Button>
      <StyledSpan/>
    </StyledBoxRow>
  </StyledBoxColumn>;
}